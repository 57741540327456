import React, { useContext } from "react";
import { SubscriptionDetailContext } from "../../../context/SubscriptionDetailContext";
import * as styles from "./styles.scss";
import { messages } from "../messages";
import { Link } from "@yoast/ui-library";
import { FormattedMessage } from "react-intl";
import { ExternalLink } from "../../ExternalLink";

/**
 * A list of included services in the subscription
 * @returns {Element} The included services.
 * @constructor
 */
export const IncludedServices = () => {
	const { subscription, request: { isFirstLoad } } = useContext( SubscriptionDetailContext );

	if ( isFirstLoad || subscription.included.inSelf.invitableServices.length === 0 ) {
		return <></>;
	}

	return <section className={ styles.includedServices }>
		<p className={ styles.title }><FormattedMessage { ...messages.productsTitle } /></p>
		<ul className={ styles.serviceList }>
			{ subscription.included.inSelf.invitableServices.map( service => {
				const actionMessage = service.link?.action.toLowerCase() === "install" ? messages.installLinkText : {};
				return <li key={ service.name }>
					<div>
						<span>{ service.name }</span>
						{ service.link &&
							<Link
								href={ service.link.url }
								size="small"
								target="_blank"
								className={ styles.link }
							>
								<FormattedMessage { ...actionMessage } />
							</Link>
						}
					</div>
				</li>;
			} ) }
		</ul>
		<p>
			<ExternalLink href={ "https://yoa.st/myyoast-installation" }>
				<FormattedMessage { ...messages.installationGuide } />
			</ExternalLink>
		</p>
	</section>;
};
